import { combineReducers } from 'redux';
import { detect } from 'detect-browser';

const 
  device = {
    ...detect()
  },
  initialToolbar = {
    active: true,
    burgerColor: 'black',
    bcgColor: 'transparent',
    button: 'close',
    buttonColor: 'white',
    mapDropdown: false,
    clickable: true,
    toolbarOpen: false
  },

  simpleSteps = [
    {"FloatingTextInput": "name"},
    {"FloatingTextInput": "family"},
    {"MapBoxEl": "region"},
    {"MapBoxEl": "mountain"},
    {"MapBoxEl": "water"}
  ],

  initialStepPosition = {
    stage: undefined, // simple or advanced
    currStep: "SplashPage", // if Simple
    prevStep: null,
    simpleStep: 0,
    simpleSteps: simpleSteps,
    explanationsShown: [false, false, false, false, false],
    addManually: false,
    mapData: false,
    mapSelection: false
  },

  initialPepehaData = {
    mountain: {
      maori: "maunga",
      visible: false,
      entry: undefined,
      sentences: {
        maori: ['ko','te maunga'],
        eng: ['','is the mountain']
      },
      simpleSentences: {
        maori: ['ko','te maunga te rū nei taku ngākau'],
        eng: ['','is the mountain that speaks to my heart']
      }
    },
    ocean: {
      maori: "moana",
      visible: false,
      sentences: {
        maori: ['ko','te moana'],
        eng: ['','is the ocean']
      }
    },
    water: {
      maori: "awa",
      visible: false,
      sentences: {
        maori: ['ko','te awa'],
        eng: ['','is the river']
      },
      simpleSentences: {
        maori: ['ko','te awa e mahea nei aku māharahara '],
        eng: ['','is the river that alleviates my worries']
      }
    },
    lake: {
      maori: "roto",
      visible: false,
      sentences: {
        maori: ['ko','te roto'],
        eng: ['','is the lake']
      }
    },
    ship: {
      maori: "waka",
      visible: false,
      sentences: {
        maori: ['ko','te waka'],
        eng: ['','is my waka']
      }
    },
    tribe: {
      maori: "iwi",
      visible: false,
      sentences: {
        maori: ['ko','te iwi'],
        eng: ['','is my tribe']
      }
    },
    people: {
      maori: "hapū",
      visible: false,
      sentences: {
        maori: ['ko','te Hapū'],
        eng: ['','are my people']
      }
    },
    meetingHouse: {
      maori: "marae",
      visible: false,
      sentences: {
        maori: ['ko','te marae'],
        eng: ['','is my marae']
      }
    },
    region: {
      maori: "rohe",
      visible: false,
      sentences: {
        maori: ['Nō','ahau'],
        eng: ['I am from','']
      },
      simpleSentences: {
        maori: ['Nō','ahau'],
        eng: ['I am from','']
      }
      
    },
    family: {
      maori: "whānau",
      visible: false,
      sentences: {
        maori: ['ko','TōKU WhāNaU'],
        eng: ['','is my family']
      },
      simpleSentences: {
        maori: ['ko','TōKU WhāNaU'],
        eng: ['','is my family']
      }
    },
    name: {
      maori: "ingoa",
      visible: false,
      sentences: {
        maori: ['ko','TōKU INGOA'],
        eng: ['My name is','']
      },
      simpleSentences: {
        maori: ['ko','TōKU INGOA'],
        eng: ['My name is','']
      }
    },
  };

const deviceInfo = (state = device) => {
  return state;
}

const toolbar = (state = initialToolbar, {type, payload}) => {
  if(type === 'CHANGE_TOOLBAR'){
    return {
      ...state,
      ...payload
    }
  }

  return state;
}  

const stepPosition = (state = initialStepPosition, {type, payload}) => {
  if(type === 'MOVE_TO'){
    return {
      ...state,
      ...payload
    }
  }

  if(type === 'MOVE_BACKWARD'){
    let prev = state.prevStep;

    return {
      ...state,
      [state.currStep]: prev
    }
  }

  if(type === 'RESET_EXPLANATIONS'){
    let newState = { ...state };

    newState.explanationsShown = [false, false, false, false, false];

    return {
      ...state,
      ...newState
    }
  }

  if(type === 'RESET_POSITION'){
    return {
      ...state,
      stage: undefined, // simple or advanced
      currStep: "SplashPage", // if Simple
      prevStep: null,
      simpleStep: 0,
      simpleSteps: simpleSteps,
      explanationsShown: [false, false, false, false, false],
      addManually: false,
      mapData: false,
      mapSelection: false
    }
  }

  return state;
}

const pepehaData = (state = initialPepehaData, {type, payload}) => {
  if(type === 'ADD_PEPEHA_ENTRY'){
    let newState = { ...state };

    if(payload.category === '') return state;

    newState[payload.category].entry = payload.value;
    newState[payload.category].visible = true;

    return {
      ...state,
      ...newState
    }
  }

  if(type === 'CHANGE_PEPEHA_ENTRY'){
    return {
      ...state,
      ...payload
    }
  }

  if(type === 'TOGGLE_ENTRY_VISIBILITY'){
    let newState = { ...state },
        currVisibility = state[payload.name].visible;

    newState[payload.name].visible = !currVisibility;

    return {
      ...state,
      ...newState
    }
  }

  if(type === 'RESET_DATA'){
    let newState = { ...state };

    Object.keys(newState).map(key => {
      newState[key].entry = undefined;
      newState[key].visible = false;
    });

    return {
      ...state,
      ...newState
    }
  }

  return state;
}

export default combineReducers({
  deviceInfo,
  toolbar,
  stepPosition,
  pepehaData,
})