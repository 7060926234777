import React, { Component, Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';

import './styles/_misc.scss';

const Home = lazy(() => import('./containers/Home'));
const About = lazy(() => import('./containers/About'));
const CreatePepeha = lazy(() => import('./containers/CreatePepeha'));
const PortraitReady = lazy(() => import('./containers/PortraitReady'));

class App extends Component {
  render() {
    return (
      <Suspense fallback={<div className="loading"></div>}> {/* loading */}
        <Switch>
          <Route exact path={process.env.PUBLIC_URL + "/"} render={ (history) => <Home/> } />
          <Route exact path={process.env.PUBLIC_URL + "/about"} render={ (history) => <About/> } />
          <Route exact path={process.env.PUBLIC_URL + "/create-pepeha"} render={ (history) => <CreatePepeha/> } />
          <Route exact path={process.env.PUBLIC_URL + "/portrait-ready"} render={ (history) => <PortraitReady/> } />
          <Route render={ (history) => <Home/> } />
        </Switch>
      </Suspense>
    );
  }
}

export default App;
